.fadeUP {
  animation: fadeUP 0.8s ease-in-out;
}

@keyframes fadeUP {
  from {
    opacity: 1;
    transform: translateY(70%);
  }
  to {
    opacity: 1;
  }
}

.navDELAY {
  animation: navdelay 3.5s ease-in-out;
}

@keyframes navdelay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// nav stuff

.nav-fade-1 {
  opacity: 0;
  animation: fadeIn 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.nav-fade-2 {
  opacity: 0;
  animation: fadeIn 0.8s;
  animation-delay: 0.55s;
  animation-fill-mode: forwards;
}
.nav-fade-3 {
  opacity: 0;
  animation: fadeIn 0.8s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.nav-fade-4 {
  opacity: 0;
  animation: fadeIn 0.5s;
  animation-delay: 0.65s;
  animation-fill-mode: forwards;
}

.nav-fade-5 {
  opacity: 0;
  animation: fadeIn 0.5s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
}
.nav-fade-6 {
  opacity: 0;
  animation: fadeIn 0.5s;
  animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

.nav-fade-7 {
  opacity: 0;
  animation: fadeIn 0.5s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}

.nav-fade-8 {
  opacity: 0;
  animation: fadeIn 0.5s;
  animation-delay: 0.85s;
  animation-fill-mode: forwards;
}
.nav-fade-9 {
  opacity: 0;
  animation: fadeIn 0.5s;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;
}
.nav-fade-10 {
  opacity: 0;
  animation: fadeIn 0.5s;
  animation-delay: 0.95s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeLeft {
  transform: translateX(-400px);
  animation: fadeLeft 0.5s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
}

.fadeRight {
  animation: fadeInRight 1s ease-in-out;
}

@keyframes fadeLeft {
  from {
    transform: translateX(-400px);
  }
  to {
    transform: translateX(0px);
    animation-delay: 3s;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateY(100%);
    animation-delay: 2s;
  }
  to {
    opacity: 1;
  }
}

#one {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  pointer-events: none;
  animation-direction: reverse;
  animation: firstAnimation 2s linear forwards;
  animation-delay: 0.8s;
}

#two {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  pointer-events: none;
  animation-direction: reverse;
  animation: firstAnimation 2s linear forwards;
  animation-delay: 1.3s;
}

#three {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  pointer-events: none;
  animation-direction: reverse;
  animation: firstAnimation 2s linear forwards;
  animation-delay: 1.8s;
}

#underline-svg {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  pointer-events: none;
  animation-direction: reverse;
  animation: firstAnimation 2s linear forwards;
  animation-delay: 0.8s;
}

@keyframes firstAnimation {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
