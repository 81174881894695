html,
body {
  font-family: "Albert Sans", sans-serif;
  color: #3d3d3c;
}

// main {
//   transform: translateZ(0);
// }

@import "flickity.scss";

.wrapper {
  width: 100%;
  margin: 0 auto;
  // max-width: calc(1180px + 40px);
  padding: 0 20px;
  @media (min-width: 800px) {
    padding: 0 60px;
  }
  @media (min-width: 1200px) {
    padding: 0 100px;
  }
}
@mixin letter-spacing($spacing) {
  letter-spacing: calc(-1em * 0.001 * #{$spacing});
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-text {
  font-size: responsive 30px 40px;
  line-height: responsive 28px 35px;
}

h1,
.h1 {
  font-size: responsive 30px 55px;
  line-height: responsive 34px 64px;
}

h2,
.h2 {
  font-size: responsive 30px 55px;
  line-height: responsive 30px 55px;
  @include letter-spacing(-0.3);
  font-weight: 300;
  @apply mb-8;
  strong {
    font-weight: 700;
  }
}

h3,
.h3 {
  font-weight: 400;
  font-size: responsive 18px 20px;
  line-height: responsive 22px 25px;
  @include letter-spacing(-0.3);
}
.description {
  font-size: responsive 20px 35px;
  line-height: responsive 30px 45px;
  @include letter-spacing(-0.3);
}
.description-large {
  font-size: responsive 20px 35px;
  line-height: responsive 30px 45px;
  @include letter-spacing(-0.3);
  font-weight: 300;
}
.overview {
  h3 {
    font-size: responsive 20px 35px;
    line-height: responsive 25px 45px;
    @include letter-spacing(-0.3);
    font-weight: 300;
    margin-bottom: 1rem;
    strong:nth-child(1) {
      @apply text-blue;
    }
    strong:nth-child(2) {
      @apply text-pink;
    }
    strong:nth-child(3) {
      @apply text-green;
    }
    strong:nth-child(4) {
      @apply text-yellow;
    }
  }
}
h4,
.h4 {
  font-size: 15px;
  @include letter-spacing(-0.3);
}

h5,
.h5 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

h6,
.h6 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

p,
.p {
  font-size: 15px;
  line-height: 22px;
  @include letter-spacing(-0.3);
  margin-bottom: 1.7em;
  &:last-of-type {
    margin-bottom: 0;
  }
}
strong {
  font-weight: 700;
}
.text-xs {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

img.lazyload:not([src]) {
  visibility: hidden;
}

.wysiwyg {
  p {
    line-height: 2em;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

a:not([class]) {
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
}
.header-text {
  p {
    font-size: responsive 20px 25px;
    line-height: responsive 28px 33px;
  }
}
.main-footer a {
  white-space: nowrap;
}

.province-select {
  overflow: hidden;
  .flickity-viewport {
    overflow: visible;
  }
}

.headroom--unpinned,
.headroom--pinned,
.headroom {
  margin: auto !important;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  padding-left: 40px !important;
  padding-right: 40px !important;
  @media (max-width: 1023px) {
    padding-right: 20px !important;
  }
  @media (max-width: 767px) {
    padding-left: 10px !important;
    padding-right: 0px !important;
  }
  .logo {
    transition: all;
    transition-duration: 400ms;
  }
}

.hero-slider {
  outline: none !important;
  transition: all 0.4s ease-in-out;
  .flickity-slider {
    transform: none !important;
  }
}
.testimony-slider {
  outline: none !important;
  transition: all 0.4s ease-in-out;
  .flickity-slider {
    transform: none !important;
  }
}

.carousel-main:hover {
  cursor: pointer;
}

.gallery-cell:focus {
  outline: none !important;
}

.gallery-cell {
  left: 0 !important;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
  transform: none !important;
}

.gallery-cell.is-selected {
  opacity: 1;
  z-index: 0;
}

button.flickity-button.flickity-prev-next-button.previous:hover {
  background-color: #7ab7bc;
  transition: all 0.2s ease-in-out;
}
button.flickity-button.flickity-prev-next-button.next:hover {
  background-color: #7ab7bc;
  transition: all 0.2s ease-in-out;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

input::placeholder,
textarea::placeholder {
  color: black !important;
  font-size: 14px !important;
}

.wysiwyg {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: 1.1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  th,
  td {
    text-align: left;
    padding-bottom: 0.75em;
    padding-right: 2em;
    vertical-align: top;
    min-width: 200px;
    white-space: nowrap;
    &:last-child {
      padding-right: 0;
    }
  }
  table {
    @extend .text-xs;
    width: 100%;
  }

  & > article {
    @media (max-width: 600px) {
      padding-bottom: 80px;
      margin-bottom: 80px;
    }

    padding-bottom: 130px;
    margin-bottom: 130px;
    border-bottom: 1px solid #313131;
  }
}

.large-statement {
  p {
    font-size: responsive 30px 55px;
    line-height: responsive 30px 55px;
    @include letter-spacing(-0.3);
    font-weight: 300;
    position: relative;
    z-index: 2;
    @apply mb-8;
    strong:nth-child(1) {
      @apply text-blue;
    }
    strong:nth-child(2) {
      @apply text-pink;
    }
    strong:nth-child(3) {
      @apply text-green;
    }
    strong:nth-child(4) {
      @apply text-yellow;
    }
  }
}

.class-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  @media (max-width: 1020px) {
    display: block;
  }
}

.class-1 {
  grid-area: 1 / 1 / 2 / 2;
}
.class-2 {
  grid-area: 1 / 2 / 2 / 3;
}
.bg-gold {
  background-color: #f6ae3c;
}
.slides-bg-yellow {
  background: #fceb6c;
}
.slides-bg-green {
  background: #b2d8c4;
}
details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
.accord-arrows::before {
  transition: all 0.5s ease-out;
  content: "+";
}
details[open] {
  .accord-arrows::before {
    content: "-";
    transition: all 0.5s ease-out;
  }
  padding-bottom: 20px;
  @media (max-width: 1024px) {
    padding-bottom: 10px;
  }
}
.slides-bg-pink {
  background: #f2ceca;
}
svg#foot-arrow {
  width: 90px;
  display: inline-block;
  margin-right: 50px;
  margin-bottom: 10px;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}
.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #66b08b;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: "";
  margin-top: -8px;
}

.menu-button::after {
  content: "";
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}
.headroom--not-top {
  background: rgba(255, 255, 255, 0.753) !important;
  box-shadow: 0 2px 6px -6px black !important;
  .logo-head {
    padding-top: 1rem !important;
    padding-bottom: 0.5rem !important;
  }
  .main-nav {
    padding-top: 1rem !important;
  }
  .nav-book {
    padding-top: 0.5rem !important;
  }
}
header.news {
  background: rgba(255, 255, 255, 0.753) !important;
  box-shadow: 0 2px 6px -6px black !important;
  .logo-head {
    padding-top: 1rem !important;
    padding-bottom: 0.5rem !important;
  }
  .main-nav {
    padding-top: 1rem !important;
  }
  .nav-book {
    padding-top: 0.5rem !important;
  }
}
.main-nav {
  ul {
    li {
      position: relative;
      &::after {
        content: "";
        width: 0%;
        height: 2px;
        background-color: #66b08b;
        position: absolute;
        left: 0;
        bottom: 0;
        transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
      }
    }
    li:hover {
      &::after {
        width: 100%;
      }
    }
  }
}
@media (max-width: 1024px) {
  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #222;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
}
.large-intro {
  p {
    font-size: responsive 25px 35px;
    line-height: responsive 35px 45px;
    font-weight: 200;
  }
}
.popup-window {
  height: calc(100vh - 200px);
  overflow: scroll;
  h4 {
    font-weight: 700;
    margin-bottom: 10px;
  }
  h3 {
    margin-bottom: 10px;
  }
  ul {
    margin-left: 20px;
    margin-bottom: 10px;
    li {
      list-style-type: initial;
    }
  }
}
.fill-green-contrast {
  fill: #c5e3d3;
}
.fill-gold-contrast {
  fill: #fceb6c;
}

.fill-pink-contrast {
  fill: #f2ceca;
}
.fill-blue-contrast {
  fill: #bed1ee;
}

body div.rbc-calendar {
  @apply text-gray-700 text-xs;
  .rbc-header {
    @apply bg-white py-2;
  }
  .rbc-month-view {
    @apply bg-white;
  }
  * {
    border-color: #e5e7eb !important;
    // border-color: #d1fae5 !important;
  }
  .rbc-btn-group:last-child {
    display: none;
  }
  // .rbc-header + .rbc-header {
  //   @apply border-l border-gray-300;
  // }
  // .rbc-month-row + .rbc-month-row {
  //   @apply border-t border-gray-300;
  // }
  .rbc-toolbar {
    flex-direction: row-reverse;
  }
  .rbc-toolbar-label {
    text-align: left;
    @apply text-lg font-semibold text-gray-900;
  }
  .rbc-off-range-bg {
    @apply bg-gray-50;
  }
  .rbc-button-link {
    pointer-events: none;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes scalein {
  from {
    opacity: 0;
    filter: blur(6px);
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    filter: none;
    transform: scale(1);
  }
}

[data-action="Menu"] {
  &:not(.hidden) {
    animation: fadein 0.2s forwards;
  }

  a {
    opacity: 0;
    animation: scalein 0.2s forwards;
  }

  @for $i from 1 to 15 {
    a:nth-child(#{$i}) {
      animation-delay: 0.2 + $i * 0.15s;
    }
  }
}
.line-clamp {
  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.rbc-event-content {
  overflow: visible !important;
  text-overflow: initial !important;
  white-space: initial !important;
}
.rbc-row-segment {
  padding: 0 1px 1px !important;
  pointer-events: none !important;
  @media (max-width: 1024px) {
    .panel-container {
      display: none !important;
    }
    .mobile-panel-container {
      display: grid !important;
    }
  }
  .mobile-panel-container {
    display: hidden !important;
  }
}
